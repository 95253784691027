import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';


import client from 'services/client';


import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import GalleryCard from 'components/GalleryCard';

import './styles.scss';

class Galleries extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            galleries: [],
            formOpen: false,
            title: "",
            canSave: false,
            confirmDelete: false,
            confirmTitle: "",
            galleryToDelete: {
                title: ""
            }
        }
    }

    handleChange = type => e => {
        console.log(type, e);
        this.setState({
            [type]: e.target.value
        }, () => {
            if(this.state.title.length > 0) {
                this.setState({
                    canSave: true
                });
            } else {
                this.setState({
                    canSave: false
                });
            }
        });
    }

    componentDidMount() {
        this.fetchGalleries();
    }

    open = gallery => {
        const win = window.open("https://constanceoules.com/project/" + gallery.title, '_blank');
        win.focus();
    }

    fetchGalleries = () => {
        client.get("/galleries").then(response => {
            this.setState({galleries: response.data.payload || []});
        }).catch(err => {
            if(err) throw err;
        });
    }

    handleCreate = () => {
        client.post("/galleries", {
            title: this.state.title
        }).then(response => {
            console.log(response.data);
            this.handleClose();
            this.fetchGalleries();
        }).catch(err => {
            if(err) throw err;
        });
    }

    handleOpen = () => this.setState({formOpen: true});
    handleClose = () => this.setState({formOpen: false});

    handleKey = e => {
        if(e.keyCode === 13 && this.state.title.length > 0) {
            this.handleCreate()
        }
    }

    openDeleteConfirmDialog = gallery => {
        console.log(gallery);
        this.setState({
            confirmDelete: true,
            galleryToDelete: gallery,
            confirmTitle: ""
        });
    }

    closeDeleteConfirmDialog = () => this.setState({confirmDelete: false});

    handleDelete = () => {
        if(this.state.confirmTitle.toLowerCase() === this.state.galleryToDelete.title.toLowerCase()) {
            client.delete("/galleries/"+ this.state.galleryToDelete.id).then(response => {
                this.fetchGalleries();
                this.closeDeleteConfirmDialog();
            }).catch(err => {
                if(err) throw err;
            });
        }
    }

    edit = gallery => {
        this.props.history.push('/galleries/'+gallery.id);
    }

    render() {
        return (
            <div className="page">
                <Button onClick={this.handleOpen} variant="extendedFab" color="primary" aria-label="Add" className="fab-button">
                    <AddIcon />
                    Créer une galerie
                </Button>

                <div className="galleries-container">
                    {this.state.galleries.map((gallery, i) => {
                        return (
                            <GalleryCard edit={() => this.edit(gallery)} count={gallery.count} open={() => this.open(gallery)} delete={()=> this.openDeleteConfirmDialog(gallery)} key={i} title={gallery.title} previewImage={gallery.preview_image}/>
                        )
                    })}
                </div>

                <Dialog
                    open={this.state.confirmDelete}
                    onClose={this.closeDeleteConfirmDialog}
                    >
                    <DialogTitle id="form-dialog-title">Supprimer définitivement cette galerie ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="body1">
                            Cette action supprimera définitivement la galerie <code color="#d40d53">{this.state.galleryToDelete.title}</code>, ainsi que toutes les photos associées.
                            Merci d'écrire le nom de la galerie pour confirmer.
                        </DialogContentText>
                        <TextField
                            value={this.state.confirmTitle}
                            onChange={e => this.setState({confirmTitle: e.target.value})}
                            margin="dense"
                            type="text"
                            fullWidth
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDeleteConfirmDialog} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={this.state.confirmTitle.toLowerCase() !== this.state.galleryToDelete.title.toLowerCase()} onClick={this.handleDelete} color="secondary">
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                fullWidth
                    open={this.state.formOpen}
                    onClose={this.handleClose}
                    
                    onSubmit={this.handleCreate}
                    >
                    <DialogTitle>Nouvelle galerie</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="body1">
                            Veuillez donner un nom à cette nouvelle galerie.
                        </DialogContentText>
                        <TextField
                            onKeyDown={e => this.handleKey(e)}
                            onChange={this.handleChange("title")}
                            autoFocus
                            margin="dense"
                            label="Titre"
                            type="text"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Annuler
                        </Button>
                        <Button disabled={this.state.title.length < 1} onClick={this.handleCreate} color="secondary">
                            Créer
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

export default Galleries;