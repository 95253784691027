import React from 'react';

import {Grid, TextField, Button, Typography} from '@material-ui/core';

import './styles.scss';

import client from 'services/client';
import UploadPictures from '../../components/UploadPictures';

class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content_fr: "",
            content_en: "",
            image_url: "",
            uploadForm: false
        }
    }
    
    componentDidMount() {
        client.get("/content/about").then(response => {
            const content = response.data.payload;
            this.setState({
                ...content
            });
        }).catch(err => {
            if(err) throw err;
        });
    }

    save = () => {
        client.post("/content/about", {
            content_fr: this.state.content_fr,
            content_en: this.state.content_en,
            image_url: this.state.image_url
        }).then(response => {
            console.log(response.data);
        }).catch(err => {
            if(err) throw err;
        });
    }

    updatePhoto = () => {
        this.setState({
            uploadForm: true
        })
    }
    
    onDone = data => {
        this.setState({image_url: data[0].payload.secure_url, uploadForm: false});
    }

    onClose = () => this.setState({
        uploadForm: false
    });

    render() {
        return (
            <div className="page about">
                <Grid container>
                    <Typography variant="h5">Page à propos</Typography>
                    <Grid item xs={12}>
                        <TextField
                            label="Contenu français"
                            variant="outlined"
                            value={this.state.content_fr}
                            onChange={e=>this.setState({content_fr: e.target.value})}
                            margin="normal"
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            label="Contenu anglais"
                            variant="outlined"
                            value={this.state.content_en}
                            onChange={e=>this.setState({content_en: e.target.value})}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Image URL"
                            variant="outlined"
                            value={this.state.image_url}
                            onChange={e => this.setState({image_url: e.target.value})}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button className="import-button" onClick={this.updatePhoto} color="primary" variant="contained">Importer une photographie</Button>
                        <br/>
                        <img alt="about" width="50%" src={this.state.image_url}/>
                    </Grid>
                    <Button className="save-button" color="primary" variant="contained" onClick={this.save}>Sauvegarder</Button>

            
                    <UploadPictures onDone={this.onDone} gallery_id="about" onClose={this.onClose} content={<span>Veuillez importer une photographie pour la page « à propos ».</span>} open={this.state.uploadForm} max={1}/>

                </Grid>
            </div>
        )
    }
}

export default About;