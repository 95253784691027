import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import client from 'services/client';

import async from 'async';

class UploadPictures extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            publishing: false,
            files: [],
            completed: 0
        }
    }

    componentWillReceiveProps() {
        /* Reset */
        this.setState({
            files: [],
            completed: 0
        });
    }

    
    handleSubmit = () => {
        const files = document.getElementById("fileInput").files;
        this.setState({publishing: true});

        let completed = 0;

        const upload = rowID => {
            const data = [];
            async.eachSeries(files, (file, next) => {
                const formData = new FormData();
                formData.append("image", file, file.name);
                formData.append("gallery", this.props.gallery_id);
                formData.append("row_id", rowID);
                formData.append("published", true);
                formData.append("work", this.props.work);
        
                client.post("/photos", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    data.push(response.data);
                    completed++;
                    next();
                    this.setState({
                        completed
                    });
                }).catch(err => {
                    if(err) {
                        this.setState({publishing: false, error: err.message});
                    }
                });
            }, () => {
                this.setState({publishing: false}, () => {
                    this.props.onDone(data);
                });
            });
        }
        if(this.props.gallery_id === "home") {
            /* If rowID is not specified, create a new row and upload photos there */
            if(!this.props.row_id) {
                client.post("/rows").then(response => {
                    const row = response.data.payload;
                    this.rowID = row.id;
                    upload(row.id);
                }).catch(err => {
                    if(err) throw err;
                });
            } else {
                this.rowID = this.props.row_id;
                upload(this.props.row_id);
            }
        } else {
            upload(null);
        }
    }

    handleImageSelect = () => {
        const fileInput = document.getElementById("fileInput");

        fileInput.addEventListener("change", () => {
            const files = fileInput.files;

            if(this.props.max) {
                if(files.length > this.props.max) {
                    alert("Trop d'images !");
                    fileInput.value = "";
                }
            }
            this.setState({canPublish: files.length > 0, files})
        });

        fileInput.click();
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.state.publishing ? null : this.props.onClose}
                fullWidth
            >
            <DialogTitle>{this.props.title || "Ajouter des photographies"}</DialogTitle>
            <DialogContent>

                <DialogContentText variant="body1">
                    {this.props.content}
                </DialogContentText>

                <div style={{display: "flex", alignItems:"center"}}>
                    <Button disabled={this.state.publishing} onClick={this.handleImageSelect} size="large" style={{marginTop: 15}} variant="contained" color="primary">Importer</Button>
                    
                    {this.props.max && this.state.files < 1 && <Typography style={{paddingTop: 15, paddingLeft: 10}}variant="body1">{this.props.max} max</Typography>}
                    {this.state.files.length > 0 && <Typography style={{paddingTop: 15, paddingLeft: 10}} variant="body1">{this.state.files.length} fichiers</Typography>}
                </div>
                {this.state.publishing && (
                    <div className="progress">
                        <CircularProgress className="circular" size={35} />
                        <Typography variant="body1">{this.state.completed} / {this.state.files.length}</Typography>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button disabled={this.state.publishing} onClick={this.props.onClose} color="primary">
                    Annuler
                </Button>
                <Button disabled={this.state.publishing || this.state.files.length < 1} onClick={this.handleSubmit} color="secondary">
                    Ajouter
                </Button>
            </DialogActions>
            <input multiple id="fileInput" type="file" accept="image/*"/>
        </Dialog>
        );
    }
}

export default UploadPictures;