import React from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import {getSize} from 'utils';

import './styles.scss';

class RowCard extends React.Component {
    render() {
        return (
            <Card className="row-card">
                <div className="previews">
                    {this.props.row.photos.map((photo, i) => (
                        <Tooltip key={i} title={getSize(photo.size)}>
                            <img
                                alt={"row-media"}
                                className="row-media"
                                src={photo.url}
                            />
                        </Tooltip>
                    ))}
                </div>
                <CardActions disableActionSpacing>
                    <Tooltip title="Modifier">
                        <IconButton onClick={this.props.onEdit}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip style={{marginLeft: "auto"}} title="Supprimer">
                        <IconButton onClick={this.props.onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
        );
    }
}

export default RowCard;