import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import './styles.scss';

class PhotoCard extends React.Component {
    render() {
        return (
            <Card className="photo-card">
                <CardMedia
                    className="photo-card-preview-media"
                    image={this.props.src}
                />
                {this.props.editing && <CardActions disableActionSpacing>
                    <Checkbox
                        checked={this.props.checked}
                        onChange={this.props.toggleCheck}
                        color="secondary"
                    />
                    <Tooltip title="Supprimer">
                        <IconButton onClick={this.props.onDelete} aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>

                    {this.props.previewButton && <Button onClick={this.props.setPreview} color="primary" style={{marginLeft: "auto", cursor: this.props.preview ? "default": "pointer"}} size="small" variant={this.props.preview ? "contained" : "outlined"}>Illustration</Button>}

                    {this.props.gallerySelector && <FormControl>
                        <InputLabel>Galerie</InputLabel>
                        <Select onChange={this.props.onGalleryChange} value={this.props.gallery}>
                            <MenuItem value={"none"}>
                                <em>Aucune</em>
                            </MenuItem>
                            {this.props.galleries.map((gallery, i) => (
                                <MenuItem key={i} value={gallery.id}>
                                    {gallery.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>}

                    {this.props.sizeSelector && <FormControl style={{marginLeft: "auto"}}>
                        <InputLabel>Taille</InputLabel>
                        <Select onChange={this.props.onSizeChange} value={this.props.size} >
                        <MenuItem selected value={0}>
                            Deux tiers
                        </MenuItem>
                        <MenuItem value={1}>
                            Moitié
                        </MenuItem>
                        <MenuItem value={2}>
                            Un tier
                        </MenuItem>
                    </Select>
                    </FormControl>}
                </CardActions>}
            </Card>
        )
    }
}

export default PhotoCard;