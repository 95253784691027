import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';

import client from 'services/client';

import './styles.scss';

import { setToken } from 'utils/token';

class Login extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: ""
        }
    }

    componentDidMount() {

    }

    login = () => {
        client.post("/authenticate", {
            username: this.state.username,
            password: this.state.password
        }).then(response => {
            console.log(response.data);
            if(response.data.status === "success") {
                const token = response.data.payload.token;
                setToken(token);
                window.location = "/";
            }
        }).catch(err => {
            if(err) throw err;
        });
    }

    handleChange = type => event => {
        this.setState({
            [type]: event.target.value
        });
    }

    render() {
        return (
            <div className="login-container">
                <Paper elevation={20} className="paper">
                    <form>
                        <Typography variant="h4">
                            Connexion
                        </Typography>
                        <FormGroup>
                            <TextField
                                label="Nom d'utilisateur ou email"
                                value={this.state.username}
                                onChange={this.handleChange('username')}
                                margin="normal" 
                            />
                            <TextField
                                type="password"
                                label="Mot de passe"
                                value={this.state.password}
                                onChange={this.handleChange('password')}
                                margin="normal" 
                            />
                            <Button onClick={this.login} size="large" variant="contained" color="primary" className="login-button">
                                Se connecter
                            </Button>
                        </FormGroup>
                    </form>
                </Paper>
            </div>
        )
    }
}

export default Login;