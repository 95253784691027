import React from 'react';
import {render} from 'react-dom';
import * as serviceWorker from './serviceWorker';

import App from './App';
import 'styles/index.scss';

/* Remove console.logs on production */
if(process.env.NODE_ENV !== "development") {
    console.log = () => {};
}

render(<App />, document.getElementById("root"));

serviceWorker.unregister();
