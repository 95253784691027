import React from 'react';
import './styles.scss';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import UploadPictures from '../../components/UploadPictures';

import client from 'services/client';
import RowCard from '../../components/RowCard';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

class Homepage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialog: false,
            rows: [],
            orderedRows: [],
            confirmDelete: false,
            rowToDelete: {},
            editing: false,
        }
    }

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
    
        const items = reorder(
            this.state.orderedRows,
            result.source.index,
            result.destination.index
        );
    
        this.setState({
            orderedRows: items,
        });
    }

    handleOpen = () => this.setState({dialog: true});
    handleClose = () => this.setState({dialog: false});

    onDone = () => {
        this.handleClose();
        this.fetchRows();
    }

    componentDidMount() {
        this.fetchRows();
    }

    fetchRows = () => {
        client.get("/rows").then(response => {
            const rows = response.data.payload || [];
            this.setState({rows, orderedRows: rows});
        }).catch(err => {
            if(err) throw err;
        });
    }
    
    edit = row => this.props.history.push('/homepage/' + row.id);
    
    closeDeleteConfirmDialog = () => this.setState({confirmDelete: false});        

    handleDelete = () => {
        client.delete('/rows/'+this.state.rowToDelete.id).then(response => {
            this.closeDeleteConfirmDialog();
            this.fetchRows();
        }).catch(err => {
            if(err) throw err;
        });
    }

    cancel = () => this.setState({
        orderedRows: this.state.rows,
        editing: false
    })

    handleSave = () => {
        const rows = Object.assign(this.state.orderedRows, {});
        for(let i = 0; i < rows.length; i++) {
            rows[i].index = rows.length - i -1;
        }

        client.put('/rows', rows).then(response => {
            this.setState({
                editing: false
            });
            this.fetchRows();
        }).catch(err => {
            if(err) throw err;
        });
    }

    render() {
        return (
            <div className="page">
                <Button onClick={this.handleOpen} variant="extendedFab" color="primary" aria-label="Add" className="fab-button">
                    <AddIcon />
                    Ajouter une ligne
                </Button>
                <Grid direction="column" container className="rows-container">
                    {this.state.orderedRows.length > 1 && <Grid className="update-buttons" item>
                        {!this.state.editing && <Button size="large" onClick={() => this.setState({editing: true})} variant="contained" color="primary">
                            Modifier l'ordre
                        </Button>}
                        {this.state.editing && <React.Fragment>
                            <Button onClick={this.handleSave} variant="contained" color="primary">
                                Sauvegarder
                            </Button>
                            <Button onClick={this.cancel} style={{marginLeft: 15}} variant="outlined" color="default">
                                Annuler
                            </Button>
                        </React.Fragment>}
                    </Grid>}
                    <Grid item>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable style={{width: "fit-content"}} droppableId="droppable">
                                {provided => (
                                    <div ref={provided.innerRef}>
                                        {this.state.orderedRows.map((row, i) => (
                                            <Draggable isDragDisabled={!this.state.editing} key={i} draggableId={row.id} index={i}>
                                                {provided => (
                                                    <div ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps} key={i}>
                                                        <div style={{padding: 10}}>
                                                            <RowCard onDelete={() => this.setState({
                                                                confirmDelete: true,
                                                                rowToDelete: row
                                                            })} onEdit={() => this.edit(row)} key={i} total={this.state.orderedRows.length} row={row}/>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.confirmDelete}
                    onClose={this.closeDeleteConfirmDialog}
                    >
                    <DialogTitle id="form-dialog-title">Supprimer définitivement cette ligne ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="body1">
                            Cette action supprimera définitivement cette ligne ainsi que toutes les photos associées.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDeleteConfirmDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDelete} color="secondary">
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>

                <UploadPictures onDone={this.onDone} gallery_id="home" max={3} open={this.state.dialog} onClose={this.handleClose} title={"Ajouter des photos à la ligne"} content={<span>Veuillez importer des photographies pour les ajouter à la <strong>page d'accueil</strong></span>}/>
            </div>
        );
    }
}

export default Homepage;