import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import './styles.scss';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

class GalleryCard extends React.Component {
    render() {
        return (
            <Card className="gallery-card">
                <CardHeader
                    title={<Typography variant="body1">{this.props.title}</Typography>}
                    subheader={<Typography variant="caption">{this.props.count || 0} photos</Typography>}
                />
                
                <CardMedia
                    onClick={this.props.edit}
                    className="gallery-card-preview-media"
                    image={this.props.previewImage || "https://cdn8.bigcommerce.com/s-mz5hl9/stencil/40b091e0-9fe5-0136-6c3f-65c8afb177d4/images/no-image.svg"}
                />
                <CardActions disableActionSpacing>
                    <Tooltip title="Ouvrir">
                        <IconButton onClick={() => this.props.open()} aria-label="Ouvrir">
                            <OpenInNewIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Modifier">
                        <IconButton onClick={this.props.edit} aria-label="Modifier">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer">
                        <IconButton onClick={this.props.delete} style={{marginLeft: "auto"}} aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
        )
    }
}

export default GalleryCard;